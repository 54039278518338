@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Dancing+Script:wght@700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@400;600&display=swap');
body {
    /* background-color: #f7fffb; */
    /* font-family: 'Comfortaa', cursive; */
    /* text-align: center; */
    font-family: 'Golos Text', sans-serif;
    background-image: url("https://cdn.pixabay.com/photo/2016/12/01/20/17/texture-1876097_960_720.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: top;
}

.app {
    width: 100%;
}

@media screen and (max-width: 400px) {
    .app {
        width: 400px;
    }
}


/* SCROLLBAR */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    /* background: #3700ff;  */
}

/* ::-webkit-scrollbar-button {
    height: 20px; 
  } */


::-webkit-scrollbar-thumb {
    background: rgb(159, 190, 194);
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(118, 143, 146);
}

h1 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.headerNavbar {
    /* left: 40%; */
    width: 100%;
    background-color: rgb(252, 255, 255, 0.9);
    /* background: linear-gradient(to bottom, rgba(230, 255, 255, 0.9) 90%, rgba(255, 255, 255, 0) 100%); */
    /* transform: translate(-40%, 0); */
    z-index: 10;
    padding-bottom: 5px;
    border-bottom: 1px solid #d8d8d8;
    /* height: 70px; */
    /* box-shadow: 0px 0px 0px 2px rgba(45, 70, 68, 0.2); */
}

/*  */




.headerTitle {
    color: #353836;
    font-size: 25px;
    display: inline-block;
    background-image: linear-gradient(#636363 0 0);
    background-position: 50% 100%;
    background-size: 0% 2px;
    background-repeat: no-repeat;
    transition: background-size 0.5s, background-position 0s 0.5s;
}

.headerTitle:hover {
    background-position: 50% 100%;
    background-size: 60% 2px;
}

.sectionTitle {
    font-size: 30px;
}

.unselectable {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.bottomButton {
    position: fixed;
    width: 40px;
    height: 40px;
    bottom: 0;
    right: 0;
    margin-bottom: 10px;
    cursor: pointer;
}

.endButton {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.codeBlock:hover {
    /* transform: scale(1.1); */
    font-weight: bold;
}

.fadeIn {
    animation: fadeIn ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.fadeOut {
    animation: fadeOut ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.dot {
    height: 10px;
    width: 10px;
    margin-right: 5px;
    background-color: #399c2c;
    border: 1px solid #399c2c;
    border-radius: 50%;
    display: inline-block;
}

.circle {
    height: 10px;
    width: 10px;
    margin-right: 5px;
    border: 1px solid #399c2c;
    border-radius: 50%;
    display: inline-block;
}

.profileImage {
    display: block;
    margin-top: 80px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    animation: popIn ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.homeSiding {
    margin-left: 20%;
    margin-right: 20%;
}

@media screen and (max-width: 1000px) {
    .homeSiding {
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 600px) {
    .hide600W {
        visibility: hidden;
        margin: 0;
        padding: 0;
        width: 0;
        height: 0;
    }
}

.categoryMarginRight {
    margin-right: 40%;
}

.categoryMarginLeft {
    margin-left: 40%;
}

.leftAlign {
    text-align: left;
}
.rightAlign {
    text-align: right;
}

@media screen and (max-width: 1000px) {
    .categoryMarginRight {
        margin-right: 0;
    }
    .categoryMarginLeft {
        margin-left: 0;
    }
    .leftAlign {
        text-align: center;
    }
    .rightAlign {
        text-align: center;
    }
}

.hideNavbarTitle {
    margin-right: 15px;
}

@media screen and (max-width: 1100px) {
    .hideNavbarTitle {
        visibility: hidden;
        width: 0;
        height: 0;
        margin: 0;
        margin-top: 0px;
    }
}




/*checklist*/

.checkList {
    margin: 0;
    /* padding-left: 20px; */
    padding-left: 0;
}

.checkList li {
    position: relative;
    list-style-type: none;
    padding-left: 20px;
    margin-bottom: 0.5rem;
    margin-left: 4rem;
    transition: .3s;
    cursor: pointer;
}

.checkList li:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: -2px;
    width: 4px;
    height: 10px;
    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: green;
    transform-origin: bottom left;
    transform: rotate(45deg);
}

.checkList li:hover {
    transform: scale(1.2);
}


/* KEYFRAMES */

@keyframes popIn {
    0% {
        width: 0px;
        height: 0px;
        opacity: 0;
    }
    100% {
        width: 200px;
        height: 200px;
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}